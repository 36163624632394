import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import * as React from "react";

import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import { useSelector, Provider, ReactReduxContext } from "react-redux";

import { Stage } from 'konva/lib/Stage';
import WebsiteStore from "../../../WebsiteStore";

import SceneRenderer from "./SceneRenderer";
import MirrorRenderer from "./MirrorRenderer";

type Props = {
    labData?: Record<string,any>,
    subproductRef?: string,
    subproductRef_labData?: Record<string,any>,
    inputType: 'lab',
    outputType: 'texture'|'render',
    style?: React.CSSProperties,
    width?: number,
    height?: number,
    scaleX?: number,
    scaleY?: number,
}

type RendererHandle = {
    getStage: () => Stage | null
    getCanvas: () => HTMLCanvasElement | null
}

function Renderer(props: Props, ref: React.Ref<RendererHandle>) {
    const variant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const subproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));

    const stageRef = useRef<Stage>(null);

    useImperativeHandle(ref, () => {
        return {
            getStage: () => {
                return stageRef.current
            },
            getCanvas: () => {
                return stageRef.current ? stageRef.current.toCanvas() : null
            }
        }
    }, []);

    if(!props.labData || !props.labData.variants[variant]) return null;

    const finalWidth = props.width || props.labData.variants[variant].dimensions[props.outputType].width;
    const finalHeight = props.height || props.labData.variants[variant].dimensions[props.outputType].height;

    const scenes = Object.keys(props.labData.variants[variant].scenes);

    return <ReactReduxContext.Consumer>
        {({ store }) => (
            <StageNode
                ref={stageRef}
                width={finalWidth}
                height={finalHeight}
                scaleX={props.scaleX}
                scaleY={props.scaleY}
                style={{
                    display: 'none',
                    ...props.style
                }}
            >
                { /** Konva Stage loses context info so we need to re-provide the store */}
                <Provider store={store}>
                    { /* Scenes */}
                    { scenes.map(scene => {
                        return <SceneRenderer
                            key={scene}
                            subproduct={subproduct}
                            subproductRef={props.subproductRef}
                            scene={scene}
                            sceneData={props.labData.variants[variant].scenes[scene]}
                            subproductRef_sceneData={props.subproductRef_labData?.variants[variant].scenes[scene]}
                            inputType={props.inputType}
                            outputType={props.outputType}
                            transparentTexture={props.labData.transparent_texture}
                            labData={props.labData}
                        />
                    })}
            
                    { /* Mirrors */}
                    { scenes.map(scene => {
                        return <MirrorRenderer
                            key={scene}
                            subproduct={subproduct}
                            subproductRef={props.subproductRef}
                            scene={scene}
                            sceneData={props.labData.variants[variant].scenes[scene]}
                            labData={props.labData}
                            subproductRef_labData={props.subproductRef_labData}
                            inputType={props.inputType}
                            outputType={props.outputType}
                            transparentTexture={props.labData.transparent_texture}
                        />
                    }) }
                </Provider>
            </StageNode>
        )}
    </ReactReduxContext.Consumer>
}

export default forwardRef(Renderer);